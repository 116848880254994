import { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { HiChevronDown } from 'react-icons/hi2';
import { FaLocationPin, FaPhone, FaHeadset } from 'react-icons/fa6';
import { FaEnvelope } from 'react-icons/fa';
import { BsFillVolumeDownFill, BsFillVolumeMuteFill } from 'react-icons/bs';

import LatestNews from 'components/LatestNews';
import PageTitle from 'elements/PageTitle';
import PremiumContact from 'components/PremiumContact';
import Modal from 'elements/Modal';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

import mapConfig from 'config/mapConfig.js';

export default function PremiumMainPage(props) {
    const [page, setPage] = useState(null);
    const [contactModalVisible, setContactModalVisible] = useState(false);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    const _data = {
        sliderBackground: getValueFromParameterMap(page, 'slider-background') && getValueFromParameterMap(page, 'slider-background').downloadUrl,
        newsList: getValueFromParameterMap(page, 'news-list') && getValueFromParameterMap(page, 'news-list').contentList,
    }

    const onContact = () => {
        setContactModalVisible(true);
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col py-0'>

            <Hero data={page} />

            <PremiumSlider data={page} />

            {/* {_data.newsList && <LatestNews data={_data.newsList} type='PREMIUM' />} */}

            <ContactStepList id='ertekajanlat' data={page} onContact={() => onContact()} />

            {/* <Bankers id='bankaraink-old' data={page} onContact={() => onContact()} /> */}

            <BankersNew id='bankaraink' data={page} onContact={() => onContact()} />

            <ImageLine data={page} />

            <ServiceAdvantageList data={page} />

            <CustomerAdvantageList id='elonyok' data={page} />

            {_data.newsList && <LatestNews data={_data.newsList} type='PREMIUM' />}

            <AboutUs id='rolunk' data={page} />

            <SubFooter id='kapcsolat' data={page} />

            {contactModalVisible && <ContactModal onClose={() => setContactModalVisible(false)} />}

        </NBossCMSPage>
    );
}

function Hero(props) {
    const [mute, setMute] = useState(true);

    const _data = {
        title: getValueFromParameterMap(props.data, 'hero-title'),
        content: getValueFromParameterMap(props.data, 'hero-content'),
    }

    return (
        <div id={props.id} className='w-full flex flex-col lg:flex-row items-center justify-end mt-24 z-10'>

            <div className='w-full lg:w-[50%] bg-premium-light text-premium flex flex-col lg:items-end pl-site order-2 lg:order-1 shadow-3xl z-10'>

                <div className='w-full lg:max-w-[720px] flex flex-col gap-10 lg:gap-8 xl:gap-12 py-10 lg:py-16 pr-8 lg:pr-24 pl-content md:text-center lg:text-left'>
                    <div className='font-display text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>{_data.title}</div>
                    <div className='text-sm paragraph-gap' dangerouslySetInnerHTML={{ __html: _data.content }} />
                </div>

            </div>

            <div className='relative w-full lg:w-[50%] bg-premium aspect-video order-1 lg:order-2 flex'>
                <button className='absolute top-0 -left-0 size-14 aspect-square bg-premium/80 hover:bg-premium transition text-white text-3xl z-10 shadow-xl flex items-center justify-center outline-none' onClick={() => { setMute(!mute) }}>
                    {mute ? <BsFillVolumeDownFill /> : <BsFillVolumeMuteFill />}
                </button>
                <video className='w-full h-full' /*ref={refVideo}*/ width='100%' height='auto' autoplay='' muted={mute}/* muted={mute ? 'true' : 'false'}*/ playsinline='' controls='true'>
                    <source src='https://premium-vagyonkezeles.hu/assets/videos/premium.mp4' type='video/mp4' />
                    {/* <source src='https://premium-vagyonkezeles.hu/vagyonkezeles/assets/video/dio.ogg' type='video/ogg' /> */}
                    Your browser does not support the video tag.
                </video>
            </div>

        </div>
    );
}

function PremiumSlider(props) {
    const _data = {
        background: getValueFromParameterMap(props.data, 'slider-background') && getValueFromParameterMap(props.data, 'slider-background').downloadUrl,
        list: getValueFromParameterMap(props.data, 'slide-list') && getValueFromParameterMap(props.data, 'slide-list').contentList,
    }

    const renderSubItem = (data) => {
        const _data = {
            image: getValueFromParameterMap(data, 'image') && getValueFromParameterMap(data, 'image').downloadUrl,
        }

        return <div id={props.id} key={_data.title} className='absolute w-full h-full bg-cover bg-no-repeat bg-center' style={{ backgroundImage: 'url(' + _data.image + ')' }}>

        </div>
    };

    const renderItem = (data) => {
        const _data = {
            title: getValueFromParameterMap(data, 'title'),
            subTitle: getValueFromParameterMap(data, 'sub-title'),
            content: getValueFromParameterMap(data, 'content'),
            subslideTitle: getValueFromParameterMap(data, 'subslide-title'),
            subslideContent: getValueFromParameterMap(data, 'subslide-sub-title'),
            subSlideList: getValueFromParameterMap(data, 'sub-slide-list') && getValueFromParameterMap(data, 'sub-slide-list').contentList,
        }

        const getRandomSubslide = () => {
            return _data.subSlideList[Math.floor(Math.random() * _data.subSlideList.length)]
        };

        return <div id={props.id} key={_data.title} className='flex flex-col lg:flex-row items-stretch'>

            <div className='relative w-full aspect-square sm:aspect-video lg:aspect-auto lg:w-[50%] flex flex-col justify-center border-b border-r-0 lg:border-r lg:border-b-0 border-premium-medium'>

                {_data.subSlideList && renderSubItem(getRandomSubslide())}

                <div className='absolute w-full h-full bg-gradient-to-r from-black/50 to-black/30' />

                <div className='absolute w-full h-full flex flex-col items-center justify-center text-white p-10'>

                    <div className='flex-1 flex flex-col justify-center xl:px-6'>
                        <div className='font-display text-2xl md:text-4xl lg:text-xl xl:text-3xl text-center lg:text-left' dangerouslySetInnerHTML={{ __html: _data.subslideTitle }}></div>

                    </div>

                    <div className='w-full flex gap-3 flex-col lg:flex-row items-center justify-between'>
                        <img alt='Gránit Prémium Vagyonkezelés' className='w-40 sm:w-60 md:w-40 2xl:w-60' src='assets/images/logo/logo-white-premium.svg' />
                        <div className='text-xs xl:text-sm' dangerouslySetInnerHTML={{ __html: _data.subslideContent }}></div>
                    </div>

                </div>

            </div>

            {/* <div className='w-full lg:w-[50%] flex flex-col justify-center border-b border-r-0 lg:border-r lg:border-b-0 border-premium-medium'>
                <div className='flex flex-col p-8 md:p-12 xl:p-16'>
                    <div className='font-display text-3xl xl:text-4xl paragraph-gap-lg' dangerouslySetInnerHTML={{ __html: _data.title }} />
                </div>
            </div> */}

            <div className='w-full lg:w-[50%] flex flex-col justify-center'>
                <div className='flex flex-col gap-6 p-8 md:p-12 xl:p-16'>
                    <div className='font-display text-3xl' dangerouslySetInnerHTML={{ __html: _data.subTitle }} />
                    <div className='text-sm leading-relaxed paragraph-gap content-text' dangerouslySetInnerHTML={{ __html: _data.content }} />
                </div>
            </div>

        </div>
    };

    return (
        <div className='relative flex flex-col items-center -mt-48 pt-48 bg-cover bg-center text-premium px-site' style={{ backgroundImage: 'url(' + _data.background + ')' }}>

            <div className='absolute top-0 left-0 w-full h-full pointer-events-none mix-blend-multiply bg-gradient-to-t from-premium-medium to-primary' />

            <div className={'w-full max-w-boxed px-content py-16 md:py-32 z-10'}>

                <div className='w-full bg-premium-light rounded-xl shadow-3xl overflow-hidden'>
                    {_data.list && _data.list.map((item) => renderItem(item))}
                </div>

            </div>

        </div>
    );
}

function ContactStepList(props) {
    const [open, setOpen] = useState(false);

    const _data = {
        title: getValueFromParameterMap(props.data, 'contact-step-title'),
        list: getValueFromParameterMap(props.data, 'contact-step-list') && getValueFromParameterMap(props.data, 'contact-step-list').contentList,
    }

    const renderItem = (data) => {
        const _data = {
            index: getValueFromParameterMap(data, 'index'),
            title: getValueFromParameterMap(data, 'title'),
            content: getValueFromParameterMap(data, 'content'),
        }

        return <div className='flex gap-6' style={{ order: _data.index }}>

            <div className='font-medium text-lg'>{(_data.index < 10 ? ('0' + _data.index) : _data.index)}.</div>

            <div className='flex flex-col gap-2'>
                <div className='font-medium text-xl'>{_data.title}</div>
                <div className='text-sm'>{_data.content}</div>
            </div>

        </div>
    };

    return (
        <div id={props.id} className='w-full bg-premium-light text-premium flex flex-col items-center py-16 lg:py-32 pt-20 lg:pt-36 px-site'>

            <div className={'w-full max-w-boxed px-content flex flex-col md:flex-row gap-16'}>

                <div className='w-full md:w-[45%] xl:w-[50%] flex flex-col items-center gap-16'>
                    <div className='text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-display'>{_data.title}</div>
                    <button className='w-full md:w-auto rounded-button-premium-reverse' onClick={() => props.onContact()}>Visszahívást kérek</button>
                </div>

                {_data.list && <div className='flex-1 flex flex-col items-center gap-16'>

                    <div className='flex flex-col gap-12'>
                        {_data.list.map((item) => (open || getValueFromParameterMap(item, 'index') <= 4) && renderItem(item))}
                    </div>

                    <button className='rounded-button-generic rounded-button-premium-reverse-color p-4 aspect-square' onClick={() => setOpen(!open)}><HiChevronDown className={'' + (open ? 'rotate-180' : 'rotate-0')} /></button>

                </div>}

            </div>

        </div>
    );
}

function Bankers(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'banker-title'),
        list: getValueFromParameterMap(props.data, 'banker-list') && getValueFromParameterMap(props.data, 'banker-list').contentList,
    }

    const renderItem = (data) => {
        const _data = {
            index: getValueFromParameterMap(data, 'index'),
            name: getValueFromParameterMap(data, 'name'),
            position: getValueFromParameterMap(data, 'position'),
            image: getValueFromParameterMap(data, 'image') && getValueFromParameterMap(data, 'image').downloadUrl,
            size: getValueFromParameterMap(data, 'size'),
        }

        const getAspectBySize = (size) => {
            if (size == 3) return 'aspect-[264/207]';
            if (size == 2) return 'aspect-[264/238]';
            return 'aspect-[264/329]'
        };

        return <div className='flex flex-col gap-4 w-[70vw] flex-none md:flex-1' key={_data.index} style={{ order: _data.index }}>

            <div className={'w-full bg-white overflow-hidden ' + getAspectBySize(_data.size)} >

                <div className='bg-cover bg-top bg-no-repeat aspect-[264/329]' style={{ backgroundImage: 'url(' + _data.image + ')' }} />

            </div>

            <div className='flex flex-col gap-2 pl-4'>
                <div className='text-lg font-medium'>{_data.name}</div>
                <div>{_data.position}</div>
            </div>

        </div>
    };

    const renderBoxedContent = (children, className) => {
        return <div className={'w-full px-site flex flex-col items-center ' + (className || '')}>
            <div className='w-full max-w-boxed flex flex-col items-center'>
                {children}
            </div>
        </div>
    };

    return (
        <div id={props.id} className='relative w-full bg-white text-premium flex flex-col items-center py-16 lg:py-32'>

            <div className='w-full flex flex-col items-center gap-16 lg:gap-20'>

                {renderBoxedContent(<div className='w-full text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-display'>{_data.title}</div>, 'order-1')}

                <div className='w-full flex flex-col items-center px-site-mobile order-3 md:order-2'>
                    <div className='w-full md:max-w-boxed flex flex-grow gap-1 overflow-x-scroll md:overflow-x-visible hidden-scrollbar px-8 sm:px-0'>
                        {_data.list && _data.list.map((item) => renderItem(item))}
                    </div>
                </div>

                {renderBoxedContent(<button className='w-full md:w-auto rounded-button-premium-lg self-center -mt-8 md:mt-0' onClick={() => props.onContact()}>Visszahívást kérek</button>, 'order-2 md:order-3')}

            </div>

        </div>
    );
}

function BankersNew(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'banker-title'),
        list: getValueFromParameterMap(props.data, 'banker-list') && getValueFromParameterMap(props.data, 'banker-list').contentList,
    }

    const renderItem = (data, className) => {
        const _data = {
            index: getValueFromParameterMap(data, 'index'),
            name: getValueFromParameterMap(data, 'name'),
            position: getValueFromParameterMap(data, 'position'),
            image: getValueFromParameterMap(data, 'image') && getValueFromParameterMap(data, 'image').downloadUrl,
            size: getValueFromParameterMap(data, 'size'),
            phone: getValueFromParameterMap(data, 'phone'),
            email: getValueFromParameterMap(data, 'email'),
        }

        const getAspectBySize = (size) => {
            if (size == 3) return 'aspect-[264/264]';
            if (size == 2) return 'aspect-[264/264]';
            return 'aspect-[264/264]'
        };

        return <div className={'flex flex-col gap-6 ' + (className || '')} key={_data.index} /*style={{ order: _data.index }}*/>

            <div className={'w-full bg-white overflow-hidden flex-1 ' + getAspectBySize(_data.size)} >

                <div className='w-full h-full bg-cover bg-top bg-no-repeat' style={{ backgroundImage: 'url(' + _data.image + ')' }} />

            </div>

            <div className='flex flex-col gap-3'>

                <div className='flex flex-col gap-0.5'>
                    <div className='text-3xl sm:text-2xl font-display leading-tight md:leading-tight'>{_data.name}</div>
                    <div className='text-base sm:text-sm leading-tight'>{_data.position}</div>
                </div>

                <div className='flex flex-col gap-1.5'>
                    {_data.email && renderContactItem(<FaEnvelope />, _data.email, 'mailto:' + _data.email)}
                    {_data.phone && renderContactItem(<FaPhone />, _data.phone, 'tel:' + _data.phone.replaceAll(' ', ''))}
                </div>

            </div>

        </div>
    };

    const renderContactItem = (icon, value, href) => {
        return (
            <div className='w-full flex gap-2 items-center text-sm sm:text-xs overflow-hidden group'>
                <div className='group-hover:text-premium-darkk'>{icon}</div>
                <div className='flex-1 overflow-hidden group-hover:text-premium-dark'><a className='w-full line-clamp-1 break-words' href={href}>{value}</a></div>
            </div>
        );
    };

    const renderBoxedContent = (children, className) => {
        return <div className={'w-full px-site flex flex-col items-center ' + (className || '')}>
            <div className='w-full max-w-boxed flex flex-col items-center'>
                {children}
            </div>
        </div>
    };

    return (
        <div id={props.id} className='relative w-full bg-white text-premium flex flex-col items-center py-16 lg:py-32'>

            <div className='w-full flex flex-col items-center gap-16 lg:gap-20'>

                {renderBoxedContent(<div className='w-full 2xl:w-[75%] text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-display'>{_data.title}</div>, 'order-1')}

                {renderBoxedContent(

                    <div className='w-full 2xl:w-[75%] flex flex-col md:flex-row gap-6 lg:gap-8 xl:gap-14'>

                        {_data.list && _data.list.map((item) => getValueFromParameterMap(item, 'size') === "1" && renderItem(item, 'flex-1'))}

                        <div className='w-full md:w-[55%] grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 xl:gap-10'>

                            {_data.list && _data.list.map((item, index) => getValueFromParameterMap(item, 'size') !== "1" && renderItem(item))}

                            <div className='w-full h-full flex flex-col gap-6 mt-4 sm:mt-0'>

                                <div className='w-full flex-1 border border-premium-light p-2'>

                                    <div className='w-full h-full border border-premium-medium bg-premium-medium/5 flex flex-col gap-4 sm:gap-8 items-center justify-center p-4 text-center'>

                                        <div className='font-display font-semibold text-3xl sm:text-xl lg:text-3xl'>Kérjen Visszahívást!</div>

                                        <div className='text-sm md:text-sm'>Munkatársaink rövid időn belül felveszik Önnel a kapcsolatot.</div>

                                        {/* <button className='w-full rounded-button-generic rounded-button-premium-color text-[10px] text-center font-semibold tracking-widest uppercase py-4' onClick={() => props.onContact()}>Visszahívást kérek</button> */}

                                    </div>

                                </div>

                                <div className='w-full flex flex-col'>

                                    <button className='w-full rounded-button-generic rounded-button-premium-color text-[10px] text-center font-semibold tracking-widest uppercase py-4' onClick={() => props.onContact()}>Visszahívást kérek</button>

                                </div>

                            </div>

                        </div>

                    </div>,

                    'order-1')}

                {/* {renderBoxedContent(<button className='w-full md:w-auto rounded-button-premium-lg self-center -mt-8 md:mt-0' onClick={() => props.onContact()}>Visszahívást kérek</button>, 'order-2 md:order-3')} */}

            </div>

        </div>
    );
}

function ImageLine(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'image-line-title'),
        content: getValueFromParameterMap(props.data, 'image-line-content'),
        image: getValueFromParameterMap(props.data, 'image-line-image') && getValueFromParameterMap(props.data, 'image-line-image').downloadUrl,
    }

    return (
        <div id={props.id} className='relative w-full bg-neutral-100 text-premium flex flex-col items-center'>

            <div className='w-full flex flex-col items-center py-16 lg:py-32 px-site'>

                <div className={'w-full max-w-boxed px-content flex flex-col md:flex-row gap-16'}>

                    <div className='w-full lg:w-[50%] lg:pr-16 xl:pr-32 flex flex-col gap-12'>
                        <div className='font-display text-4xl'>{_data.title}</div>
                        <div className='text-sm paragraph-gap' dangerouslySetInnerHTML={{ __html: _data.content }} />
                    </div>

                </div>

            </div>

            <div className='lg:absolute right-0 top-0 h-full w-full lg:w-[50%] aspect-video lg:aspect-auto bg-premium-light bg-cover bg-center bg-no-repeat' style={{ backgroundImage: 'url(' + _data.image + ')' }} />

        </div>
    );
}

function ServiceAdvantageList(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'service-advantages-title'),
        content: getValueFromParameterMap(props.data, 'service-advantages-content'),
        list: getValueFromParameterMap(props.data, 'service-advantages-list') && getValueFromParameterMap(props.data, 'service-advantages-list').contentList,
    }

    const renderItem = (data) => {
        const _data = {
            index: getValueFromParameterMap(data, 'index'),
            title: getValueFromParameterMap(data, 'title'),
            content: getValueFromParameterMap(data, 'content'),
            icon: getValueFromParameterMap(data, 'icon') && getValueFromParameterMap(data, 'icon').downloadUrl,
        }

        return <div className='flex items-start gap-6 lg:gap-10' key={_data.index} style={{ order: _data.index }}>

            <img className='size-14 lg:size-[82px]' src={_data.icon} />

            <div className='flex-1 flex flex-col gap-4'>
                <div className='font-medium text-xl'>{_data.title}</div>
                <div className='text-sm' dangerouslySetInnerHTML={{ __html: _data.content }} />
            </div>

        </div>
    };

    return (
        <div id={props.id} className='relative w-full bg-neutral-50 text-premium flex flex-col items-center py-16 lg:py-32 px-site'>

            <div className={'w-full max-w-boxed px-content flex flex-col gap-16 lg:gap-20'}>

                <div className='w-full flex flex-col lg:flex-row gap-8 lg:gap-0'>
                    <div className='flex-1 lg:pr-16 font-display text-4xl lg:text-4xl 2xl:text-5xl'>{_data.title}</div>
                    <div className='w-full lg:w-[50%] font-display text-xl lg:text-2xl 2xl:text-3xl' dangerouslySetInnerHTML={{ __html: _data.content }} />
                </div>

                {_data.list && <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 lg:mr-20'>
                    {_data.list.map((item) => renderItem(item))}
                </div>}

            </div>

        </div>
    );
}

function CustomerAdvantageList(props) {
    const [open, setOpen] = useState(false);

    const _data = {
        title: getValueFromParameterMap(props.data, 'customer-advantages-title'),
        list: getValueFromParameterMap(props.data, 'customer-advantages-list') && getValueFromParameterMap(props.data, 'customer-advantages-list').contentList,
    }

    const renderItem = (data) => {
        const _data = {
            index: getValueFromParameterMap(data, 'index'),
            title: getValueFromParameterMap(data, 'title'),
            content: getValueFromParameterMap(data, 'content'),
        }

        return <div className='p-8 sm:p-10 xl:p-12 flex gap-4 sm:gap-6 border-b border-r border-premium-medium -m-[1px]' key={_data.index} style={{ order: _data.index }}>

            <div className='font-medium text-lg'>{(_data.index < 10 ? ('0' + _data.index) : _data.index)}.</div>

            <div className='flex flex-col gap-2'>
                <div className='font-medium text-xl'>{_data.title}</div>
                <div className='text-sm'>{_data.content}</div>
            </div>

        </div>
    };

    return (
        <div id={props.id} className='w-full flex flex-col items-center bg-premium-light text-premium py-16 lg:py-32 px-site md:pb-72 lg:pb-[410px]'>

            <div className={'w-full max-w-boxed px-content flex flex-col gap-16 lg:gap-20'}>

                <div className='w-full 2xl:w-[60%] font-display text-2xl sm:text-3xl lg:text-3xl xl:text-4xl'>{_data.title}</div>

                <div className={'relative flex flex-col overflow-hidden ' + (open ? 'h-auto' : 'h-96')}>

                    {_data.list && <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 overflow-hidden border border-premium-medium rounded-xl'>

                        {_data.list.map((item) => renderItem(item))}

                    </div>}

                    <div className={'absolute left-0 bottom-0 w-full h-[50%] bg-gradient-to-t from-premium-light ' + (open ? 'opacity-0 pointer-events-none' : 'opacity-100')} />

                </div>

                <button className='rounded-button-premium-reverse self-center' onClick={() => setOpen(!open)}>További ügyfélelőnyök</button>

            </div>

        </div>
    );
}

function AboutUs(props) {
    const [open, setOpen] = useState(false);

    const _data = {
        title: getValueFromParameterMap(props.data, 'about-us-title'),
        content: getValueFromParameterMap(props.data, 'about-us-content'),
    }

    return (
        <div id={props.id} className='w-full flex flex-col gap-16 items-center bg-premium-medium pb-page-title'>

            <PageTitle
                paddingClassName='pt-page-title'
                colorClassName='bg-premium-medium'
                titleClassName='text-premium'
                splitClassName='xl:w-[70%]'
                disableContentPadding
                title={_data.title}
                contentClassName={'text-premium content-text ' + (open ? '' : 'line-clamp-6')}
                content={_data.content}
            />

            <button className='rounded-button-premium-reverse' onClick={() => setOpen(!open)}>{open ? 'Bezár' : 'Bővebben'}</button>

        </div>
    );
}

function SubFooter(props) {
    const _data = {
        contactTitle: getValueFromParameterMap(props.data, 'contact-contact-title'),
        title: getValueFromParameterMap(props.data, 'contact-title'),
        content: getValueFromParameterMap(props.data, 'contact-content'),
        mapDataLat: getValueFromParameterMap(props.data, 'contact-map-lat') && parseFloat(getValueFromParameterMap(props.data, 'contact-map-lat')),
        mapDataLng: getValueFromParameterMap(props.data, 'contact-map-lng') && parseFloat(getValueFromParameterMap(props.data, 'contact-map-lng')),
    }

    return (
        <div id={props.id} className='w-full flex flex-col items-center pt-16 lg:pt-32 bg-secondary text-white px-site'>

            <div className={'w-full max-w-boxed px-content flex flex-col gap-16 lg:gap-20'}>

                <div className='text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-display'>Kapcsolat</div>

                <div className='w-full flex flex-col md:flex-row items-stretch'>

                    <div className='w-full md:w-[50%] border-b md:border-b-0 md:border-r border-white/10 pr-0 md:pr-10 lg:pr-16 pb-16 md:py-4'>

                        <div className='w-full flex flex-col gap-12'>

                            <div className='w-full xl:w-[60%] text-lg'>{_data.contactTitle}</div>

                            <PremiumContact
                                inputClassName='line-input-white-dark-background'
                                buttonClassName='rounded-button-white'
                                bottomContainerClassName='flex flex-col gap-10 items-stretch md:items-end'
                                inlineLinkClassName='hover:text-accent font-semibold transition' />

                        </div>

                    </div>

                    <div className='w-full md:w-[50%] flex flex-col gap-12 pl-0 md:pl-10 lg:pl-16 pt-16 md:py-4'>

                        <div className='text-lg font-semibold uppercase'>{_data.title}</div>

                        <div className='text-base md:text-sm lg:text-base content-text' dangerouslySetInnerHTML={{ __html: _data.content }} />

                        {(_data.mapDataLat && _data.mapDataLng) && <div className='relative md:flex-1 w-full h-64 rounded-xl overflow-hidden'>

                            <GoogleMapReact
                                key='map'
                                bootstrapURLKeys={{ key: 'AIzaSyD1luTKqk_RQjga3FZ2vTeFJhms3nDrjSg', language: 'hu-HU', region: 'hu', }}
                                options={map => ({ disableDefaultUI: true, styles: mapConfig.darkStyles, })}
                                defaultCenter={{ lat: _data.mapDataLat, lng: _data.mapDataLng }}
                                yesIWantToUseGoogleMapApiInternals
                                defaultZoom={15}
                                disableDefaultUI={true}>

                                {/* <div className='w-[10000%] h-[10000%] -translate-x-1/2 -translate-y-1/2 bg-premium-dark/20'></div> */}

                                <div className='relative w-0 h-0 flex flex-col items-center z-10' lat={_data.mapDataLat} lng={_data.mapDataLng}>
                                    <FaLocationPin className='absolute bottom-0 text-accent text-4xl' />
                                </div>

                            </GoogleMapReact>

                        </div>}

                    </div>

                </div>

            </div>

        </div>
    );
}

function ContactModal(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        excerpt: getValueFromParameterMap(props.data, 'excerpt'),
        date: getValueFromParameterMap(props.data, 'date'),
        content: getValueFromParameterMap(props.data, 'content'),
        email: getValueFromParameterMap(props.data, 'contact-email'),
    }

    return (
        <Modal className='flex flex-col gap-4 ' colorClassName='bg-premium-light text-premium' onClose={props.onClose}>

            <div className='flex flex-col gap-3'>
                <div className='font-display text-5xl mb-1'>Visszahívást kérek</div>
                <div className='flex items-center gap-4'>
                    <FaHeadset className='text-4xl' />
                    <div className='text-sm'>Munkatársaink örömmel felveszik Önnel a kapcsolatot, kérjük, adja meg elérhetőségeit amin keresztül elérhetjük Önt.</div>
                </div>
            </div>

            <PremiumContact
                className='w-full'
                inputClassName='line-input-premium'
                buttonClassName='rounded-button-premium'
                bottomContainerClassName='flex flex-col items-center md:flex-row gap-16 2xl:gap-16' />

        </Modal>
    );
}
import { useState, useEffect } from 'react';
import Moment from 'react-moment';
import 'moment/locale/hu';
import { PiCalendarBlankFill } from 'react-icons/pi';

import { DocumentDownload } from 'pages/DocumentDownloadPage';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function ContentPage(props) {
    const [page, setPage] = useState(null);
    const [isNews, setIsNews] = useState(false);

    const { setHeaderLight, setHeaderDark } = useWebsite();

    useEffect(() => {
        if (page && getValueFromParameterMap(page, 'content').contentType === 'news-item') setIsNews(true);
        else setIsNews(false);
    }, [page]);

    useEffect(() => {
        if (isNews) setHeaderDark();
        else setHeaderLight();
    }, [isNews]);

    const _data = {
        content: getValueFromParameterMap(page, 'content'),
        legacyFolderId: getValueFromParameterMap(page, 'content') && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'legacy-folder-id'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex-1 justify-between flex flex-col bg-white'>

            {_data.content && <Content isNews={isNews} data={_data.content} />}

            {_data.legacyFolderId && <DocumentDownload legacyFolderId={parseInt(_data.legacyFolderId)} />}

        </NBossCMSPage>
    );
}

export function Content(props) {
    const _data = {
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        date: getValueFromParameterMap(props.data, 'date'),
        author: getValueFromParameterMap(props.data, 'author'),
        title: getValueFromParameterMap(props.data, 'title'),
        subTitle: getValueFromParameterMap(props.data, 'sub-title'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        content: getValueFromParameterMap(props.data, 'content'),
        externalAppearanceTitle: getValueFromParameterMap(props.data, 'external-appearance-title'),
        externalAppearanceUrl: getValueFromParameterMap(props.data, 'external-appearance-url'),
        externalAppearanceButtonLabel: getValueFromParameterMap(props.data, 'external-appearance-button-label'),
    }

    return (
        <div className='w-full flex flex-col'>

            {props.isNews && <div className={'relative w-full bg-primary flex flex-col items-center justify-end px-site pt-page-title pb-10 sm:pb-16 ' + (_data.image ? 'min-h-[320px] sm:min-h-[420px]' : '')}>

                <div className='absolute top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat mix-blend-multiply grayscale opacity-75' style={{ backgroundImage: 'url(' + _data.image + ')' }} />

                <div className='w-full max-w-boxed text-white flex flex-col gap-4 z-10 px-content'>
                    <div className='font-display text-2xl sm:text-3xl lg:text-4xl xl:text-5xl'>{_data.title}</div>
                    {_data.date && <div className='flex items-center gap-1.5 text-xs font-semibold uppercase'><PiCalendarBlankFill /><Moment format='YYYY. MMMM DD., HH:MM' withTitle>{new Date(_data.date)}</Moment></div>}
                </div>

            </div>}

            <div className={'w-full flex flex-col items-center px-site ' + (props.isNews ? 'pt-12 ' : 'pt-36') + ' ' + (_data.externalAppearanceUrl ? 'pb-12' : 'pb-36')}>

                <div className='w-full max-w-boxed flex flex-col px-content'>

                    <div className='flex flex-col text-primary-dark'>

                        {!props.isNews && <div className='flex flex-col gap-4 mb-6'>
                            <div className='font-display text-2xl sm:text-3xl lg:text-4xl xl:text-5xl'>{_data.title}</div>
                            {_data.date && <div className='flex items-center gap-1.5 text-xs font-semibold uppercase'><PiCalendarBlankFill /><Moment format='YYYY. MMMM DD., HH:MM' withTitle>{new Date(_data.date)}</Moment></div>}
                        </div>}

                        {_data.subTitle && <div className='mb-8 text-2xl font-semibold'>{_data.subTitle}</div>}
                        {_data.lead && <div className='mb-8 text-md font-medium' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

                        {_data.content && <div className='text-sm content-text leading-loose' dangerouslySetInnerHTML={{ __html: _data.content }} />}

                    </div>

                </div>

            </div>

            {_data.externalAppearanceUrl && <div className='w-full bg-primary text-white flex flex-col items-center px-site py-12'>

                <div className='w-full max-w-boxed flex flex-col px-content'>

                    <div className='w-full flex flex-col lg:flex-row lg:items-center justify-between gap-8'>

                        <div className='hidden lg:flex flex-col gap-0 opacity-75'>
                            {_data.author && <div className='text-sm'>© {_data.author}</div>}
                            {_data.date && <div className='flex items-center gap-1.5 text-xs font-semibold uppercase'><PiCalendarBlankFill /><Moment format='YYYY. MMMM DD., HH:MM' withTitle>{new Date(_data.date)}</Moment></div>}
                        </div>

                        <div className='flex-1 flex flex-col md:flex-row sm:items-center justify-end gap-4'>
                            <div className={'flex-1 font-display lg:text-right ' + ((_data.externalAppearanceTitle && _data.externalAppearanceTitle.length > 64) ? 'text-base sm:text-lg' : 'text-2xl')}>{_data.externalAppearanceTitle ? _data.externalAppearanceTitle : 'A teljes cikket az allábbi linken olvashatja:'}</div>
                            <a href={_data.externalAppearanceUrl} className='rounded-button-accent'>{_data.externalAppearanceButtonLabel ? _data.externalAppearanceButtonLabel : 'Tovább'}</a>
                        </div>

                    </div>

                </div>

            </div>}

        </div>
    );
}
import axios from 'axios';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { MdOutlineClose } from 'react-icons/md';
import { FiArrowRight } from 'react-icons/fi';
import TagManager from 'react-gtm-module'
import Portal from 'react-overlays/Portal';

import PageTitle from 'elements/PageTitle';

import { isValidEmail, isValidPhone } from 'utils/GenericUtils';

export default function PremiumContact(props) {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsAcceptedError, setTermsAcceptedError] = useState(null);

    const [newsletterSubscribe, setNewsletterSubscribe] = useState(false);

    const [feedback, setFeedback] = useState(false);

    const validate = () => {
        let _nameError = null;
        let _emailError = null;
        let _phoneError = null;
        let _termsAcceptedError = null;

        if (name == '') _nameError = 'Kérjük, adja meg nevét!';

        if (email == '') _emailError = 'Kérjük, adja meg e-mail címét!';
        else if (!isValidEmail(email)) _emailError = 'Kérjük, ellenőrizze az e-mail címét!';

        if (phone == '') _phoneError = 'Kérjük, adja meg telefonszámát!';
        else if (!isValidPhone(phone)) _phoneError = 'Kérjük, ellenőrizze a telefonszámát!';

        if (!termsAccepted) _termsAcceptedError = 'A feltételek elfogadása kötelező!';

        setNameError(_nameError);
        setEmailError(_emailError);
        setPhoneError(_phoneError);
        setTermsAcceptedError(_termsAcceptedError);

        if (!_nameError && !_emailError && !_phoneError && !_termsAcceptedError) {
            sendContactRequest();
            if (newsletterSubscribe)
                sendNewsletterSubscribe();
        }
    };

    const sendContactRequest = () => {
        // { 'name': 'CREO TESZT - NÉV', 'phone': '+36309078135', 'email': 'creo@teszt.hu', 'callback': null, 'websiteContext': 'diofa-portal' }

        const url = 'https://www.diofaalapkezelo.hu/backings/premiumClientControl/storeClient'
        const body = { 'name': name, 'email': email, 'phone': phone, 'callback': null, 'websiteContext': 'diofa-portal' }
        console.log(body);

        axios.post(url, body).then(function (response) {
            if (response.data && response.data.result && response.data.result.successful) {
                setFeedback(true);
                if (props.gtagEventName) TagManager.dataLayer({ 'dataLayer': { 'event': props.gtagEventName } });
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    const sendNewsletterSubscribe = () => {
        // { 'input': { 'name': 'CREO TESZT - NÉV', 'email': 'creo@teszt.hu', 'phone': '+36309078135', 'termsAccepted': true, 'websiteContext': 'diofa-portal', 'isActive': true, 'engage': true } }

        const url = 'https://www.diofaalapkezelo.hu/backings/subscriberControl/sendSubscriber'
        const body = { 'input': { 'name': name, 'email': email, 'phone': phone, 'termsAccepted': true, 'websiteContext': 'diofa-portal', 'isActive': true, 'engage': true } }
        console.log(body);

        axios.post(url, body).then(function (response) {
            if (response.data && response.data.result && response.data.result.successful)
                console.log('Subscribed to newsletter.');
        }).catch(function (error) {
            console.log(error);
        });
    };

    useEffect(() => { setNameError(null) }, [name]);
    useEffect(() => { setEmailError(null) }, [email]);
    useEffect(() => { setPhoneError(null) }, [phone]);
    useEffect(() => { setTermsAcceptedError(null) }, [termsAccepted]);

    useEffect(() => {
        if (!feedback) {
            setName('');
            setNameError(null);

            setEmail('');
            setEmailError(null);

            setPhone('');
            setPhoneError(null);

            setTermsAccepted(false);
            setTermsAcceptedError(null);

            setNewsletterSubscribe(false);
        }
    }, [feedback]);

    const errorMessageClassName = 'text-xs font-bold';
    const inlineLinkClassName = 'font-semibold hover:text-premium-dark transition'

    return (
        <div className={'relative w-full overflow-hidden ' + (props.className || '')}>

            <div className={'flex flex-col transition-all ' + (props.compact ? 'gap-8' : 'gap-10') + ' ' + (feedback ? 'scale-75 opacity-25' : 'scale-100 opacity-100')}>

                <div className={'flex flex-col ' + (props.compact ? 'gap-3' : 'gap-6')}>

                    <div className='flex flex-col gap-2'>
                        <input className={props.inputClassName} placeholder='Név' value={name} onChange={(e) => setName(e.target.value)} />
                        {nameError && <div className={errorMessageClassName}>{nameError}</div>}
                    </div>

                    <div className='flex flex-col gap-2'>
                        <input className={props.inputClassName} placeholder='E-mail cím' value={email} onChange={(e) => setEmail(e.target.value)} />
                        {emailError && <div className={errorMessageClassName}>{emailError}</div>}
                    </div>

                    <div className='flex flex-col gap-2'>
                        <input className={props.inputClassName} placeholder='Telefonszám' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        {phoneError && <div className={errorMessageClassName}>{phoneError}</div>}
                    </div>

                </div>

                <div className={props.bottomContainerClassName}>

                    <div className={'flex flex-col gap-4 ' + (props.compact ? 'text-[10px] 2xl:text-xs 2xl:leading-relaxed' : 'text-xs')}>

                        {props.newsletter && <label className='flex flex-row gap-2'>
                            <input className='w-4 h-4' type='checkbox' name='newsletterSubscribe' checked={newsletterSubscribe} onChange={(e) => setNewsletterSubscribe(!newsletterSubscribe)} />
                            <div>Hozzájárulok az <a className={props.inlineLinkClassName ? props.inlineLinkClassName : inlineLinkClassName} href='https://diofaalapkezelo.hu/backings/diofaFileRepository/getFileByKey?key=I4gPjgyeLCFx3kOsoyBIjSVNUlfFxW8o4YQg3pslZi7zsRH6Qx'>Adatvédelmi tájékoztatóban</a> foglaltakhoz, és feliratkozom a hírlevélre.</div>
                        </label>}

                        <label className='flex flex-row gap-2'>
                            <input className='w-4 h-4' type='checkbox' name='termsAccepted' checked={termsAccepted} onChange={(e) => setTermsAccepted(!termsAccepted)} />
                            <div className='flex flex-col gap-1'>
                                <div>Hozzájárulok, hogy az Adatkezelő által kezelt adatokhoz az <a className={props.inlineLinkClassName ? props.inlineLinkClassName : inlineLinkClassName} href='https://diofaalapkezelo.hu/backings/diofaFileRepository/getFileByKey?key=Qd5keYI1UFbAqUW4j1b6lFGMKaBqKPH2LpHKr4MtgtVGXAy0fY'>Adatvédelmi tájékoztatóban</a> meghatározott adatfeldolgozók az ott meghatározott adatkezelési célból hozzáférjenek.</div>
                                {termsAcceptedError && <div className={errorMessageClassName}>{termsAcceptedError}</div>}
                            </div>
                        </label>

                    </div>

                    <button className={props.buttonClassName} onClick={() => validate()}>Elküldöm</button>

                </div>

            </div>

            <div className={'absolute top-0 left-0 flex flex-col justify-between w-full h-full bg-premium/10 backdrop-blur shadow-2xl transition-all border-b-4 border-premium p-6 ' + (feedback ? 'scale-100 opacity-100' : 'pointer-events-none opacity-0 scale-125')}>

                <div className='flex flex-col gap-8'>
                    <div className='font-display text-3xl sm:text-4xl'>Köszönjük megkeresését!</div>
                    <div className={'' + (props.compact ? 'text-xs' : 'text-sm')}>Kapcsolatfelvételi igényét továbbítottuk kollégáink felé, akik a lehető legrövidebb időn belül felveszik Önnel a kapcsolatot, hogy segítségére lehessenek estleges kérdéseival kapcsolatban.</div>
                </div>

                <button className={props.buttonClassName} onClick={() => setFeedback(false)}>Vissza</button>

            </div>

        </div>
    );
}

export function PremiumContactRow(props) {
    return (
        <div className='w-full flex flex-col items-center border-b-0 border-premium overflow-hidden'>

            <PageTitle titleClassName='text-4xl sm:text-5xl lg:text-5xl 2xl:text-6xl' paddingClassName='pt-16 pb-16 md:pt-32 md:pb-32' colorClassName='bg-premium-light text-premium' title='Prémium vagyonkezelés' subTitle='Bízza megtakarításait szakértőinkre!' subTitleClassName='xl:mt-2' disableContentPadding
                contentChildren={<div className='flex flex-col gap-10'>

                    <div className='w-full flex flex-row items-start gap-4'>

                        <div className='w-12 aspect-square bg-contain bg-center bg-no-repeat' style={{ backgroundImage: 'url(/assets/images/etc/premium-group-icon.svg)' }} />

                        <div className='w-full flex flex-col gap-2 pt-2'>
                            <h2 className='text-3xl md:text-4xl text-premium font-display'>Lépjen be prémium ügyfélkörünkbe!</h2>
                            <h3 className='text-sm'>Munkatársaink rövid időn belül felveszik Önnel a kapcsolatot.</h3>

                        </div>

                    </div>

                    <PremiumContact
                        inputClassName='line-input-premium'
                        buttonClassName='rounded-button-premium'
                        bottomContainerClassName='flex flex-col items-center md:flex-row gap-16 2xl:gap-40'
                        gtagEventName='registrationComplete2' />

                </div>}
                titleChildren={<a href='https://premium-vagyonkezeles.hu/' className='icon-button-premium'>Tovább a Prémium Vagyonkezelésre<FiArrowRight /></a>}
            />

        </div>
    );
}

export function PremiumContactFloater(props) {
    const [open, setOpen] = useState(false);

    const localStorageName = 'granit-pvk-floater';

    useEffect(() => {
        const floaterOpenCookie = localStorage.getItem(localStorageName);
        let show = true;
        if (floaterOpenCookie && floaterOpenCookie === 'false') show = false;
        if (show) setTimeout(() => setOpen(true), 1000);
    }, []);

    return (
        <Portal>
            <div className='fixed z-[1000] right-0 inset-y-1/2 flex flex-col justify-center text-premium'>

                <motion.div
                    className='absolute right-0 w-80 xl:w-96 flex flex-col gap-4 bg-premium-light/95 backdrop-blur shadow-3xl p-8 2xl:p-10'
                    style={{ originX: 1, originY: 0.5, transformPerspective: 1000 }}
                    transition={{ duration: .5 }}
                    initial={open ? 'visible' : 'hidden'}
                    animate={open ? 'visible' : 'hidden'}
                    variants={{ 'hidden': { rotateY: -160, opacity: 0.75 }, 'visible': { rotateY: 0, opacity: 1 } }}>

                    <button className='text-button-premium self-end' onClick={() => { localStorage.setItem(localStorageName, !open); setOpen(!open); }}>Bezár<MdOutlineClose /></button>

                    <div className='w-full flex flex-col mb-1.5'>
                        <h2 className='text-2xl 2xl:text-3xl text-premium font-display pb-2'>Lépjen be prémium ügyfélkörünkbe!</h2>
                        <h3 className='text-sm pb-4'>Munkatársaink rövid időn belül felveszik Önnel a kapcsolatot.</h3>
                        <a href='https://premium-vagyonkezeles.hu/' className='icon-button-premium-small'>Ismerje meg szolgáltatásainkat<FiArrowRight /></a>
                    </div>

                    <PremiumContact
                        inputClassName='line-input-premium-small'
                        buttonClassName='rounded-button-premium'
                        bottomContainerClassName='flex flex-col gap-6'
                        compact
                        newsletter
                        gtagEventName='registrationComplete' />

                </motion.div>

                <motion.button
                    className='absolute right-0 w-14 h-14 bg-black/20 backdrop-blur border-r-4 border-accent shadow-3xl flex items-center justify-center group'

                    // className='absolute right-0 w-14 h-14 bg-premium-light/95 border-l-4 border-premium backdrop-blur-sm shadow-3xl flex items-center justify-center'
                    onClick={() => { localStorage.setItem(localStorageName, !open); setOpen(!open); }}
                    style={{ originX: 1, originY: 0.5, transformPerspective: 1000 }}
                    transition={{ duration: .5 }}
                    initial={open ? 'hidden' : 'visible'}
                    animate={open ? 'hidden' : 'visible'}
                    variants={{ 'hidden': { rotateY: 160, opacity: 0.75 }, 'visible': { rotateY: 0, opacity: 1 } }}>

                    <div className='relative w-[31px] h-[31px]'>
                        <div className='absolute top-0 left-0 w-full h-full bg-contain bg-center bg-no-repeat opacity-100 group-hover:opacity-50 transition' style={{ backgroundImage: 'url(/assets/images/etc/premium-callback-white.png)' }} />
                        <div className='absolute top-0 left-0 w-full h-full  bg-contain bg-center bg-no-repeat opacity-0 group-hover:opacity-100 transition' style={{ backgroundImage: 'url(/assets/images/etc/premium-callback-accent.png)' }} />
                    </div>

                </motion.button>

            </div>
        </Portal>
    );
}
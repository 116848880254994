import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

import PageTitle from 'elements/PageTitle';
import { NewsItemContent } from 'pages/NewsListPage';

import { getValueFromParameterMap } from 'cms/NBossCMS';

export default function LatestNews(props) {
    const getFirstHighlight = () => {
        if (props.data)
            for (let i = 0; i < props.data.length; i++)
                if (getValueFromParameterMap(props.data[i], 'highlight'))
                    return props.data[i];
        return null;
    };

    const getListWithoutHighlight = () => {
        let list = [];
        if (props.data)
            for (let i = 0; i < props.data.length; i++)
                if (!getValueFromParameterMap(props.data[i], 'highlight') || (props.type && props.type === 'PREMIUM'))
                    list.push(props.data[i]);
        return list;
    };

    const getOrderedList = (list) => {
        let _list = list.sort((a, b) => (getValueFromParameterMap(a, 'date') > getValueFromParameterMap(b, 'date')) ? 1 : ((getValueFromParameterMap(b, 'date') > getValueFromParameterMap(a, 'date')) ? -1 : 0));
        _list.reverse();
        return _list;
    };

    return (
        <div className='w-full flex flex-col items-center'>

            {(!props.type || props.type !== 'PREMIUM') && <div className='w-full md:pb-56 lg:pb-80'>
                <PageTitle paddingClassName='py-16 md:py-24' titleClassName='xl:w-min' title='Legfrissebb híreink'
                    contentChildren={<div className='w-full'><HiglightItem data={getFirstHighlight()} /></div>}
                    titleChildren={<Link to='/hirek' className='icon-button-white'>Összes korábbi hírünk<FiArrowRight /></Link>} />
            </div>}

            <div className={'w-full flex flex-col items-center px-site-mobile ' + (!props.type || props.type !== 'PREMIUM' ? 'bg-neutral-50 ' : 'bg-premium-medium')}>
                <div className='w-full md:max-w-boxed flex md:grid md:grid-cols-3 overflow-x-scroll md:overflow-x-visible hidden-scrollbar'>
                    {props.data && getOrderedList(getListWithoutHighlight()).map((item, index) => (index < 3 && <Item key={item.id} data={item} type={props.type} />))}
                </div>
            </div>

        </div>
    );
}

function HiglightItem(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        excerpt: getValueFromParameterMap(props.data, 'excerpt'),
    }

    return (
        <div className='flex flex-col items-start gap-10 text-white xl:max-w-[380px]'>

            <div className='flex flex-col gap-6'>
                <div className='text-xl md:text-2xl lg:text-3xl xl:text-4xl font-display'>{_data.title}</div>
                <div className='text-xs md:text-sm leading-relaxed md:leading-relaxed'>{_data.excerpt}</div>
            </div>

            <Link to={'/hirek/' + props.data.key} className='rounded-button-accent-reverse'>Tovább</Link>

        </div>
    );
}

function Item(props) {
    const _data = {
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        title: getValueFromParameterMap(props.data, 'title'),
        excerpt: getValueFromParameterMap(props.data, 'excerpt'),
        date: getValueFromParameterMap(props.data, 'date'),
        category: getValueFromParameterMap(props.data, 'category'),
    }

    return (
        <div className='relative w-[70vw] md:w-full flex flex-shrink-0 flex-col'>

            <div className='absolute w-full left-0 top-0 bottom-0 md:-top-56 lg:-top-80 md:bottom-20'>

                <div className={'absolute w-full top-0 left-0 bottom-20 md:bottom-0  bg-cover bg-center bg-no-repeat ' + ((props.type && props.type === 'PREMIUM') ? 'bg-premium-medium' : 'bg-primary')} style={{ backgroundImage: 'url(' + _data.image + ')' }} />

                {!_data.image && <div className={'absolute top-0 left-0 w-full h-56 lg:h-80 bg-gradient-to-b flex items-center justify-center ' + ((props.type && props.type === 'PREMIUM') ? 'from-premium-light to-premium-medium' : 'from-accent/5 to-primary')}>
                    <img alt='Gránit Alapkezelő' className='w-[40%] opacity-100' src={(props.type && props.type === 'PREMIUM') ? 'assets/images/logo/initial-premium.svg' : 'assets/images/logo/initial-accent.svg'} />
                </div>}

                {(_data.category && (!props.type || props.type !== 'PREMIUM')) && <div className='relative w-full flex gap-2 flex-wrap p-6 lg:p-8 z-10'>{_data.category.map((item) => (<CategoryTag key={item.id} data={item} />))}</div>}

            </div>

            <div className='w-full h-full flex pl-6 lg:pl-8 mt-56 md:mt-0 z-10'>

                <div className={'w-full h-full flex flex-col items-start justify-between gap-10 bg-white md:shadow-2xl p-8  border-b-2  ' + ((props.type && props.type === 'PREMIUM') ? 'border-premium text-premium-dark' : 'border-accent text-primary')}>

                    <NewsItemContent data={props.data} type={props.type} />

                </div>

            </div>

        </div>
    );
}

function CategoryTag(props) {
    const _data = {
        label: getValueFromParameterMap(props.data, 'label'),
    }

    return (
        <div className='rounded-full bg-secondary px-3 lg:px-4 py-2 text-xxs lg:text-xs leading-none lg:leading-none font-medium text-white uppercase shadow-2xl'>{_data.label}</div>
    );
}